import Image from "next/image";

export function AssetIcon({
    url,
    url2,
    width,
    height,
    className,
}: {
    url: string;
    url2?: string;
    width: number;
    height: number;
    className?: string;
}) {
    return (
        <div className={`relative flex shrink-0 ${className || ""}`}>
            <Image src={url} alt="icon" width={width} height={height} className="z-1 rounded-full" />
            {url2 && <Image src={url2} alt="icon" width={width} height={height} className="-ml-2 rounded-full" />}
        </div>
    );
}
