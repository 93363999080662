import BigNumber from "bignumber.js";
import { FormattedNumber } from "./FormattedNumber";
export function PriceChange({
    price,
    price24hAgo,
    className,
    "data-testid": dataTestId,
}: {
    price: string;
    price24hAgo: string;
    className?: string;
    "data-testid"?: string;
}) {
    const currentPrice = new BigNumber(price);
    const previousPrice = new BigNumber(price24hAgo);

    if (currentPrice.isNaN() || previousPrice.isNaN()) {
        return <span data-testid={dataTestId} className={className}></span>;
    }

    const difference = currentPrice.minus(previousPrice);
    const percentageChange = difference.dividedBy(previousPrice);

    return (
        <FormattedNumber
            data-testid={dataTestId}
            number={percentageChange.toString()}
            className={`${percentageChange.gt(0) ? "text-green-900" : percentageChange.lt(0) ? "text-red-900" : ""} ${className || ""}`}
            options={{
                style: "percent",
                fractionDigits: 2,
                abbreviateNumbers: true,
            }}
        />
    );
}
