"use client";
import { SearchModal } from "@/app/_components/overlay/search/Search";
import React, { createContext, useCallback, useEffect, useState } from "react";

const SearchContext = createContext({
    isSearchOpen: false,
    openSearch: () => {},
    closeSearch: () => {},
    setListening: (listening: boolean) => {},
});

export const useSearch = () => React.useContext(SearchContext);

export default function SearchProvider({ children }: { children: React.ReactNode }) {
    const [isSearchOpen, setIsOpen] = useState(false);
    const [listening, setListening] = useState(true);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "/" && listening) {
                if (!isSearchOpen) {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsOpen(true);
                }
            } else if (event.key === "Escape") {
                if (isSearchOpen) {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsOpen(false);
                }
            }
        },
        [isSearchOpen, listening],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <SearchContext.Provider
            value={{
                isSearchOpen,
                openSearch: () => setIsOpen(true),
                closeSearch: () => setIsOpen(false),
                setListening,
            }}
        >
            {children}
            {isSearchOpen && <SearchModal />}
        </SearchContext.Provider>
    );
}
