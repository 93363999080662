import {
    fetchAssets,
    fetchIndexPrices,
    fetchPair,
    fetchPairs,
    fetchTickers,
    mergeTickers,
} from "@/app/_hooks/datafetching";
import { Pair, Status, Ticker } from "@/app/_hooks/types";
import { useQuery } from "@tanstack/react-query";

export type MergedTicker = Ticker & Pair;

export function useAssets() {
    const { data: assets, isLoading } = useQuery({
        queryKey: ["assets"],
        queryFn: () => fetchAssets(),
    });

    return { assets, isLoading };
}
export function useTickers() {
    const { data: pairs, isLoading: pairsLoading } = useQuery({
        queryKey: ["pairs"],
        queryFn: () => fetchPairs(),
    });

    const { data: tickers, isLoading: tickersLoading } = useQuery({
        queryKey: ["tickers"],
        queryFn: () => fetchTickers(),
    });

    const isLoading = tickersLoading || pairsLoading;

    const mergedTickers = mergeTickers(tickers ?? [], pairs ?? []);
    const filteredTickers = mergedTickers.filter((ticker) => ticker.status === Status.Listed);
    return { tickers: filteredTickers, isLoading };
}

export function usePairs() {
    const { data: pairs, isLoading } = useQuery({
        queryKey: ["pairs"],
        queryFn: () => fetchPairs(),
    });

    const filteredPairs = pairs?.filter((pair) => pair.status === Status.Listed);

    return { pairs: filteredPairs, isLoading };
}

export function usePair(symbol: string) {
    const { data: pair, isLoading } = useQuery({
        queryKey: ["pair", symbol],
        queryFn: () => fetchPair(symbol),
    });

    return { pair: pair, isLoading };
}

export function useIndexPrices() {
    const { data: indexPrices, isLoading } = useQuery({
        queryKey: ["index-prices"],
        queryFn: () => fetchIndexPrices(),
    });

    return { indexPrices, isLoading };
}
