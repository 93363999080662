"use client";
import { useSearch } from "@/app/_contexts/search";
import React, { ComponentProps, forwardRef, MutableRefObject, useRef, useState } from "react";

type InputProps = {
    children?: React.ReactNode;
    padding?: string;
    label?: string;
    error?: string;
    success?: string;
    textColor?: string;
    placeholderTextColor?: string;
    bgColor?: string;
    borderColor?: string;
    className?: string;
    buttonSide?: "left" | "right";
} & Omit<ComponentProps<"input">, "className">;

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            children,
            error,
            success,
            padding = ".6rem",
            label,
            textColor = "text-gray-1000",
            placeholderTextColor = "text-gray-800",
            bgColor = "bg-slate-blue-300",
            borderColor = "border-slate-blue-1000",
            className,
            buttonSide = "right",
            disabled,
            ...props
        },
        forwardedRef,
    ) => {
        const internalRef = useRef<HTMLInputElement | null>(null) as MutableRefObject<HTMLInputElement | null>;
        const [interacted, setInteracted] = useState(false);
        const [focus, setFocus] = useState(false);

        const { setListening } = useSearch();
        const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            setListening(false);
            if (props.onFocus) props.onFocus(e);
            setFocus(true);
        };

        const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            if (props.onBlur) props.onBlur(e);
            setInteracted(true);
            setFocus(false);
            setListening(true);
        };

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) props.onChange(e);
        };

        const handleContainerClick = () => {
            internalRef.current?.focus();
        };

        const setRefs = (element: HTMLInputElement | null) => {
            internalRef.current = element;
            if (typeof forwardedRef === "function") {
                forwardedRef(element);
            } else if (forwardedRef) {
                forwardedRef.current = element;
            }
        };

        return (
            <div className={`flex flex-col gap-1 ${className}`}>
                {label && <span className={`mb-0.5 ${textColor}`}>{label}</span>}
                <div
                    className={`flex flex-row rounded border border-solid ${bgColor} ${
                        focus ? "border-blue-500" : borderColor
                    } ${disabled ? "cursor-not-allowed" : "cursor-text"}`}
                    onClick={!disabled ? handleContainerClick : undefined}
                    onKeyDown={!disabled ? (e) => e.key === "Enter" && handleContainerClick() : undefined}
                >
                    {buttonSide === "left" && children}
                    <input
                        {...props}
                        disabled={disabled}
                        style={{ ...props.style, padding }}
                        className={`w-full border-none bg-transparent outline-hidden ${textColor} placeholder:${placeholderTextColor} ${disabled ? "cursor-not-allowed" : "cursor-text"}`}
                        ref={setRefs}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    {buttonSide === "right" && children}
                </div>
                {error && interacted && (
                    <span className="rounded-b bg-red-900/[0.17] px-3 py-1.5 text-red-900">{error}</span>
                )}
                {success && interacted && (
                    <span className="rounded-b bg-green-900/[0.17] px-3 py-1.5 text-green-900">{success}</span>
                )}
            </div>
        );
    },
);

Input.displayName = "Input";
