"use client";
import BigNumber from "bignumber.js";
import { MouseEventHandler } from "react";
import styles from "./FormattedNumber.module.css";

export interface ExtendedNumberFormatOptions extends Intl.NumberFormatOptions {
    abbreviateNumbers?: boolean; // abbreviate to k, m, b, t format
    fractionDigits?: number; // force max and min number of fraction digits
    showSubscript?: boolean; // show subscript format for small numbers
}

export type NumberFormatterProps = {
    number?: string | BigNumber;
    unit?: string;
    unitColor?: "inherit" | "gray";
    options?: ExtendedNumberFormatOptions;
    locales?: string | string[];
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    "data-testid"?: string;
};

export const formatNumber = (
    num: BigNumber,
    formatOptions: ExtendedNumberFormatOptions,
    locales?: string | string[],
): string => {
    if (num.isNaN()) return "0";

    if (!num.isFinite()) {
        if (formatOptions.style === "percent") {
            return "∞%";
        } else {
            return "∞";
        }
    }

    if (formatOptions.style === "currency" && !formatOptions.currencyDisplay) {
        try {
            new Intl.NumberFormat(locales, {
                ...formatOptions,
                currencyDisplay: "narrowSymbol",
                currency: formatOptions.currency || "USD",
            });
            formatOptions.currencyDisplay = "narrowSymbol";
        } catch (e) {
            formatOptions.currencyDisplay = "symbol";
        }
    }

    if (formatOptions.fractionDigits != null) {
        formatOptions.minimumFractionDigits = formatOptions.fractionDigits;
        formatOptions.maximumFractionDigits = formatOptions.fractionDigits;
    }

    let formattedNumber = "";
    let suffix = "";
    let divisor = new BigNumber(1);

    if (formatOptions.abbreviateNumbers) {
        if (num.gte(new BigNumber(1e12))) {
            divisor = new BigNumber(1e12);
            suffix = "T";
        } else if (num.gte(new BigNumber(1e9))) {
            divisor = new BigNumber(1e9);
            suffix = "B";
        } else if (num.gte(new BigNumber(1e6))) {
            divisor = new BigNumber(1e6);
            suffix = "M";
        } else if (num.gte(new BigNumber(1e3))) {
            divisor = new BigNumber(1e3);
            suffix = "K";
        }
    }

    if (divisor.gt(1)) {
        const dividedNumber = num.dividedBy(divisor);
        const numberFormatter = new Intl.NumberFormat(locales, formatOptions);
        formattedNumber = numberFormatter.format(dividedNumber.toNumber()) + suffix;
    } else {
        const numberFormatter = new Intl.NumberFormat(locales, formatOptions);
        formattedNumber = numberFormatter.format(num.toNumber());
    }
    return formattedNumber;
};

export function FormattedNumber({
    number,
    unit,
    unitColor = "gray",
    options = {},
    locales,
    className,
    onClick,
    "data-testid": dataTestId,
}: NumberFormatterProps) {
    if (number === undefined || number === null) {
        return (
            <span data-testid={dataTestId} className={className}>
                -
            </span>
        );
    }
    const num = new BigNumber(number);

    return onClick ? (
        <button type="button" className={`${className} ${styles.button}`} onClick={onClick} data-testid={dataTestId}>
            {formatNumber(num, options, locales)}
            {unit && (
                <>
                    &nbsp;
                    <span className={styles[unitColor]}>{unit}</span>
                </>
            )}
        </button>
    ) : (
        <span className={className} data-testid={dataTestId}>
            {formatNumber(num, options, locales)}
            {unit && (
                <>
                    &nbsp;
                    <span className={styles[unitColor]}>{unit}</span>
                </>
            )}
        </span>
    );
}
