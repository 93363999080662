import { components } from "../_types/models.gen";

export interface AssetWithBalance extends Asset {
    balance: string;
    balanceUSDT: string;
    free: string;
    freeUSDT: string;
    priceUSDT: string;
}

export type Balance = components["schemas"]["Balance"];

export type Margin = components["schemas"]["Margin"];

export type Session = components["schemas"]["Session"];

export type WatchlistResponse = number[];

export type CandleDuration = components["schemas"]["CandleDuration"];

export type Candle = components["schemas"]["Candle"];

export type CandlesResponse = Candle[];

export type L2Update = components["schemas"]["L2Update"];

export type Trade = components["schemas"]["Trade"];

export type Ticker = components["schemas"]["Ticker"];

export type OrderBookEntry = components["schemas"]["OrderBookEntry"];

export type OrderBook = components["schemas"]["OrderBook"];

export type OrderBookResponse = OrderBook;

export type Asset = components["schemas"]["Asset"];

export const PairType = {
    Spot: "spot",
    Perpetual: "perpetual",
} as const;
export type PairType = (typeof PairType)[keyof typeof PairType];

export type Pair = components["schemas"]["Pair"];

export const OrderType = {
    LimitGtc: "limitGtc",
    LimitIoc: "limitIoc",
    LimitFok: "limitFok",
    Market: "market",
} as const;
export type OrderType = (typeof OrderType)[keyof typeof OrderType];

export type OrderSide = components["schemas"]["OrderSide"];

export const OrderStatus = {
    New: "new",
    Taker: "taker",
    Booked: "booked",
    Maker: "maker",
    Cancelled: "cancelled",
    Closed: "closed",
} as const;
export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

export const TriggerType = {
    None: "none",
    TakeProfit: "takeProfit",
    StopLoss: "stopLoss",
} as const;
export type TriggerType = (typeof TriggerType)[keyof typeof TriggerType];

export const TriggerPriceType = {
    LastPrice: "last",
    IndexPrice: "index",
    MarkPrice: "mark",
} as const;
export type TriggerPriceType = (typeof TriggerPriceType)[keyof typeof TriggerPriceType];

export const TriggerOrderStatus = {
    Staged: "staged",
    Triggered: "triggered",
    Cancelled: "cancelled",
} as const;
export type TriggerOrderStatus = (typeof TriggerOrderStatus)[keyof typeof TriggerOrderStatus];

export type Position = components["schemas"]["Position"];

export type CreateOrderRequest = components["schemas"]["CreateOrderRequest"];

export type CreateOrderResponse = components["schemas"]["CreateOrderResponse"];

export type CancelOrderRequest = components["schemas"]["CancelOrderRequest"];

export type CancelOrderResponse = components["schemas"]["CancelOrderResponse"];

export type CancelAllRequest = components["schemas"]["CancelAllRequest"];

export type CancelAllResponse = components["schemas"]["CancelAllResponse"];

export type CreateTriggerOrderRequest = components["schemas"]["CreateTriggerOrderRequest"];

export type CreateTriggerOrderResponse = components["schemas"]["CreateTriggerOrderResponse"];

export type CancelTriggerOrderRequest = components["schemas"]["CancelTriggerOrderRequest"];

export type CancelTriggerOrderResponse = components["schemas"]["CancelTriggerOrderResponse"];

export type CancelAllTriggerOrdersRequest = components["schemas"]["CancelAllTriggerOrdersRequest"];

export type CancelAllTriggerOrdersResponse = components["schemas"]["CancelAllTriggerOrdersResponse"];

export type Order = components["schemas"]["Order"];

export type TriggerOrder = components["schemas"]["TriggerOrder"];

export type UserTrade = components["schemas"]["UserTrade"];

export type UserInfoResponse = components["schemas"]["User"];

export type PairsResponse = Pair[];

export type AssetsResponse = Asset[];

export type UpdateSettingsRequest = components["schemas"]["UpdateUserSettingsRequest"];

export type UserSettings = components["schemas"]["UserSettings"];

export const AlertType = {
    Critical: "critical",
    Warning: "warning",
    Info: "info",
    Success: "success",
} as const;
export type AlertType = (typeof AlertType)[keyof typeof AlertType];

export type Alert = components["schemas"]["Alert"];

export type Chain = components["schemas"]["Blockchain"];

export const ChainType = {
    Mock: -1,
    EVM: 1,
    Bitcoin: 2,
    Tron: 3,
    Solana: 4,
    Near: 5,
    Ton: 6,
    Doge: 7,
    Sui: 8,
    Ripple: 9,
} as const;
export type ChainType = (typeof ChainType)[keyof typeof ChainType];

export type WithdrawalAddress = components["schemas"]["WithdrawalAddress"];

export type Subaccount = components["schemas"]["SubaccountWithSettings"];

export type SubaccountSettingsRequest = components["schemas"]["SubaccountSettingsRequest"];

export type NotificationType = components["schemas"]["NotificationType"];

export type Notification = components["schemas"]["Notification"];

export const PriceAlertType = {
    Above: "above",
    Below: "below",
} as const;
export type PriceAlertType = (typeof PriceAlertType)[keyof typeof PriceAlertType];

export const AlertPriceType = {
    LastPrice: "last",
    MarkPrice: "mark",
    IndexPrice: "index",
} as const;
export type AlertPriceType = (typeof AlertPriceType)[keyof typeof AlertPriceType];

export const Status = {
    Staged: "staged",
    Listed: "listed",
    Delisted: "delisted",
} as const;
export type Status = (typeof Status)[keyof typeof Status];

export type MarginSchedule = components["schemas"]["MarginSchedule"];

export type MarginScheduleName = components["schemas"]["MarginScheduleName"];

export type MarginScheduleBand = components["schemas"]["MarginScheduleBand"];

export type ApiKey = components["schemas"]["ApiKey"];

export type AllMarginResponse = components["schemas"]["Margin"][];

export type RewardsVoucher = components["schemas"]["RewardsVoucher"];

export type IndexPrice = components["schemas"]["IndexPrice"];
