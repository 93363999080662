import { useSearch } from "@/app/_contexts/search";
import { PairType, Status } from "@/app/_hooks/types";
import { MergedTicker, useTickers } from "@/app/_hooks/useFetch";
import { Link, useRouter } from "@arkham/i18n";
import Fuse from "fuse.js";
import { useTranslations } from "next-intl";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useOnClickOutside } from "usehooks-ts";
import { AssetIcon } from "../../ui/images/AssetIcon";
import { PriceChange } from "../../ui/number/PriceChange";
import styles from "./Search.module.css";
type TickerData = MergedTicker & {
    score: number | undefined;
};

const SearchOverlayTabs = ["all", "spot", "perpetual"] as const;
type SearchOverlayTab = (typeof SearchOverlayTabs)[number];

export function SearchModal() {
    const t = useTranslations("SearchModal");
    const { tickers } = useTickers();

    const [searchQuery, setSearchQuery] = useState("");
    const [activeTab, setActiveTab] = useState<SearchOverlayTab>("all");
    const [highlighted, setHighlighted] = useState<{ section: number; index: number } | undefined>(undefined);

    const containerRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const { isSearchOpen, closeSearch } = useSearch();

    const router = useRouter();
    // @ts-expect-error usehooks not updated to react 19
    useOnClickOutside(containerRef, () => closeSearch());

    useEffect(() => {
        if (isSearchOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isSearchOpen]);

    const mergedList = useMemo(() => {
        if (!tickers) return [];
        return tickers.map((item) => ({
            ...item,
            score: 0,
        }));
    }, [tickers]);

    const filteredList = useMemo(() => {
        let filtered = mergedList;

        filtered = filtered.filter((pair) => pair.status === Status.Listed);

        if (activeTab !== "all") {
            filtered = filtered.filter((pair) =>
                activeTab === "spot" ? pair.pairType === PairType.Spot : pair.pairType === PairType.Perpetual,
            );
        }

        if (searchQuery) {
            const fuse = new Fuse(filtered, {
                keys: [
                    { name: "baseSymbol", weight: 1 },
                    { name: "quoteSymbol", weight: 0.5 },
                    { name: "baseAsset.name", weight: 1 },
                    { name: "quoteAsset.name", weight: 0.5 },
                ],
                includeScore: true,
                shouldSort: true,
            });

            const results = fuse.search(searchQuery);
            return results.map((result) => ({ ...result.item, score: result.score }));
        }

        return filtered.map((item) => ({ ...item, score: 0 }));
    }, [mergedList, searchQuery, activeTab]);

    const sections: { title: string; pairs: TickerData[] }[] = [{ title: "Popular Tokens", pairs: filteredList }];

    const itemRefs = useRef<{ [sectionIndex: number]: (HTMLDivElement | null)[] }>(
        Object.fromEntries(sections.map((_, index) => [index, []])),
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                event.stopPropagation();
                setHighlighted((prev) => {
                    if (sections.length === 0) return;

                    let section = prev?.section ?? 0;
                    let index = prev?.index ?? -1;

                    if (index < sections[section].pairs.length - 1) {
                        index += 1;
                    } else {
                        section = (section + 1) % sections.length;
                        index = 0;
                    }
                    itemRefs.current[section][index]?.scrollIntoView({
                        block: "nearest",
                        inline: "nearest",
                    });

                    return { section, index };
                });
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                event.stopPropagation();
                setHighlighted((prev) => {
                    if (sections.length === 0) return;
                    let section = prev?.section ?? 0;
                    let index = prev?.index ?? 0;

                    if (index > 0) {
                        index -= 1;
                    } else {
                        section = (section - 1 + sections.length) % sections.length;
                        index = sections[section].pairs.length - 1;
                    }

                    itemRefs.current[section][index]?.scrollIntoView({
                        block: "nearest",
                        inline: "nearest",
                    });
                    return { section, index };
                });
            } else if (event.key === "Enter" && highlighted) {
                event.preventDefault();
                event.stopPropagation();
                const item = sections[highlighted.section]?.pairs[highlighted.index];
                if (!item) return;
                router.push(`/trade/${item.symbol}`);
                closeSearch();
            } else {
                if (sections.length > 0) {
                    const section = 0;
                    const index = 0;
                    itemRefs.current[section][index]?.scrollIntoView({
                        block: "nearest",
                        inline: "nearest",
                    });
                    setHighlighted({ section, index });
                }
            }
        },
        [filteredList, highlighted, closeSearch],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleCloseClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeSearch();
        }
    };

    return (
        <div className="fixed inset-0 z-9999 bg-black/45" onClick={handleCloseClick} role="presentation">
            <div className={styles.box} ref={containerRef} aria-modal="true">
                <div className={styles.upperSection}>
                    <div className={styles.headerRow}>
                        <div className={styles.headerRowLeft}>
                            <div className={styles.header}>
                                <div className="md:hidden">{t("searchFilter")}</div>
                                <div className="hidden md:flex">{t("tokenSearch")}</div>
                            </div>
                            <div className={styles.tabs}>
                                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
                            </div>
                        </div>
                        <button onClick={() => closeSearch()} className={styles.closeIcon} aria-label="Close Search">
                            <CgClose />
                        </button>
                    </div>
                    <div className={styles.searchRowHeader}>
                        <button onClick={() => closeSearch()} className={styles.backIcon} aria-label="Go Back">
                            <BsChevronLeft />
                        </button>
                        <input
                            className={styles.searchInput}
                            type="text"
                            placeholder={t("searchPlaceholder")}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            ref={inputRef}
                        />
                    </div>
                </div>
                <div className={styles.scrollable} ref={scrollableRef}>
                    {sections.map((section, sectionIndex) => {
                        if (section.pairs.length === 0) return null;
                        return (
                            <React.Fragment key={section.title}>
                                <div className={styles.subHeading}>{section.title}</div>
                                {section.pairs.map((pair, pairIndex) => (
                                    <SearchRow
                                        key={pair.symbol}
                                        pair={pair}
                                        closeSearch={closeSearch}
                                        isHighlighted={
                                            highlighted?.section === sectionIndex && highlighted?.index === pairIndex
                                        }
                                        onMouseEnter={() => setHighlighted({ section: sectionIndex, index: pairIndex })}
                                        ref={(el) => {
                                            itemRefs.current[sectionIndex][pairIndex] = el;
                                        }}
                                    />
                                ))}
                            </React.Fragment>
                        );
                    })}
                    {filteredList.length === 0 && <div className={styles.subHeading}>{t("noResults")}</div>}
                </div>
            </div>
        </div>
    );
}

const SearchRow = forwardRef<
    HTMLDivElement,
    {
        pair: TickerData;
        closeSearch: () => void;
        isHighlighted: boolean;
        onMouseEnter: () => void;
    }
>(({ pair, closeSearch, isHighlighted, onMouseEnter }, ref) => {
    return (
        <Link
            href={`/trade/${pair.symbol}`}
            className={`${styles.searchRow} ${isHighlighted ? styles.highlighted : ""}`}
            onClick={closeSearch}
            onMouseEnter={onMouseEnter}
        >
            <div className={styles.searchRowLeft} ref={ref}>
                <AssetIcon url={pair.baseImageUrl} width={22} height={22} />
                <div className={styles.searchRowVertical}>
                    <div>
                        <span>{pair.baseName}</span>
                        &nbsp;&nbsp;
                        <span className={styles.baseSymbol}>{pair.baseSymbol}</span>
                        <span>&nbsp;/&nbsp;</span>
                        <span>{pair.quoteSymbol}</span>
                    </div>
                    <div className={styles.pairType}>{pair.pairType}</div>
                </div>
            </div>
            <div className={styles.searchRowRight}>
                <div>{pair.price}</div>
                <div>
                    <PriceChange price={pair.price} price24hAgo={pair.price24hAgo} />
                </div>
            </div>
        </Link>
    );
});

SearchRow.displayName = "SearchRow";

const Tabs = ({
    activeTab,
    setActiveTab,
}: {
    activeTab: SearchOverlayTab;
    setActiveTab: (tab: SearchOverlayTab) => void;
}) => {
    return (
        <div className={styles.tabContainer}>
            {SearchOverlayTabs.map((tab) => (
                <button
                    key={tab}
                    className={`${styles.tabButton} ${activeTab === tab ? styles.active : ""}`}
                    onClick={() => setActiveTab(tab)}
                    role="tab"
                    tabIndex={activeTab === tab ? 0 : -1}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};
